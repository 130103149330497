<template>
  <div
    class="HomeView">
    <HeaderStep
      :logo="{ ...(flow?.headerLogo), color: '#FFFFFF' }"
      :theme="currentStep.headerTheme"
      :title="$t('flows.domo-everywhere.steps.home.title')"
      :subtitle="$t('flows.domo-everywhere.steps.home.subtitle')"
    />

    <div class="container" style="padding-bottom: 80px;">
      <form
        id="HomeView"
        @submit.prevent="handleSubmit">
        <div class="Field">
          <InputText
            class="Field__input"
            ref="input"
            v-model.trim="session.email.value"
            type="email"
            :placeholder="session.email.placeholder"
            :is-placeholder-persistent="true"
            :is-disabled="isSubmitting"
            :is-invalid="isEmailError"
            :input-style="{ border: 'none !important' }"
          />

          <ButtonBase
            v-if="isManualPartnerEntry"
            class="Field__button uppercase"
            key="inline-submit-button"
            type="submit"
            :is-loading="isSubmittingEmail">
            {{ $t('ctas.signUp') }}
          </ButtonBase>
        </div>

        <ErrorMessage
          class="mt-1"
          v-show="isManualPartnerEntry && (isEmailError || isEmailRateLimitError)">
          <span v-html="computedEmailErrorMessage"></span>
        </ErrorMessage>

        <template
          v-if="!isManualPartnerEntry">
          <div
            class="PartnerBox">
            <div
              class="font-semi-bold">
              {{ $t('flows.domo-everywhere.steps.home.pleaseEnterPartner') }}
            </div>

            <InputText
              class="mt-1"
              v-model="session.partner.value"
              type="text"
              :placeholder="session.partner.placeholder"
              :input-style="{ background: '#FFFFFF' }"
              :is-placeholder-persistent="true"
              :is-disabled="isSubmitting"
              :is-invalid="hasSubmitted && !session.partner.isValidClientSide"
            />
          </div>

          <ErrorTile
            class="mt-2"
            v-show="computedManualPartnerEntryErrorMessage">
            <span v-html="computedManualPartnerEntryErrorMessage"></span>
          </ErrorTile>

          <ButtonBase
            class="uppercase block width-100 mt-2"
            type="submit"
            :is-loading="isSubmitting">
            {{ $t('ctas.signUp') }}
          </ButtonBase>
        </template>
      </form>

      <div
        class="__legal-sign-in">
        <div
          class="__legal"
          v-html="$t('legal.acknowledgePrivacyPolicy')">
        </div>

        <div
          class="__SignIn">
          {{ $t('ctas.alreadyDomoUser') }}
          <a
            class="__legal-link font-semi-bold ml-half"
            href="https://www.domo.com/login">
            {{ $t('ctas.signIn') }}
          </a>
        </div>
      </div>

      <LogoBanner
        class="__LogoBanner"
        :label="$t('shared.trustedBy')"
        :breakpoints="[
          { width: 0, columns: 3, rows: 1 },
          { width: 550, columns: 4, rows: 1 },
        ]"
        :logos="[
        {
          name: 'Taylormade',
          maxWidth: 120,
          src: 'https://web-assets.domo.com/miyagi/images/customer/taylormade/logo/logo-customer-taylormade-black.svg',
        },
        {
          name: 'Univision',
          maxWidth: 60,
          src: 'https://web-assets.domo.com/miyagi/images/customer/univision/logo/logo-customer-univision-black.svg',
        },
        {
          name: 'BBVA',
          maxWidth: 80,
          src: 'https://web-assets.domo.com/miyagi/images/customer/bbva/logo/logo-customer-bbva-black.svg',
        },
        {
          name: 'DHL',
          src: 'https://web-assets.domo.com/miyagi/images/customer/dhl/logo/logo-customer-dhl-black.svg',
        },
      ]"
      />

      <ListIcons
        v-if="computedFeatures.list"
        class="mt-4"
        :items="computedFeatures.list"
        key="home-features">
      </ListIcons>
    </div>
  </div>
</template>


<script>
import useEmail from '@/use/email';
import usePartner from '@/use/partner';
import ButtonBase from '@/components/ButtonBase.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import ErrorTile from '@/components/ErrorTile.vue';
import HeaderStep from '@/components/HeaderStep.vue';
import InputText from '@/components/InputText.vue';
import ListIcons from '@/components/ListIcons.vue';
import LogoBanner from '@/components/LogoBanner.vue';

export default {
  name: 'HomeView',

  components: {
    ButtonBase,
    ErrorMessage,
    ErrorTile,
    HeaderStep,
    InputText,
    ListIcons,
    LogoBanner,
  },

  inject: [
    'flow',
    'session',
    'currentStep',
    'trackEvent',
  ],

  setup() {
    const {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    } = useEmail();

    const {
      fetchPartnerEnv,
    } = usePartner();

    return {
      fetchPartnerEnv,
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    };
  },

  data() {
    return {
      hasSubmitted: false,
      isPartnerRemoteError: false,
      isPartnerSubmitting: false,
    };
  },

  computed: {
    computedFeatures() {
      const stepMessages = this.$i18n.messages[this.$i18n.locale].flows['domo-everywhere']?.steps?.home;

      return {
        list: stepMessages?.features,
      };
    },

    computedEmailErrorMessage() {
      return this.isEmailRateLimitError
        ? this.$t('shared.rateLimitError')
        : this.$t('shared.businessEmailError');
    },

    computedManualPartnerEntryErrorMessage() {
      if (!this.hasSubmitted) return null;
      if (!this.session.partner.isValidClientSide) return this.$t('flows.domo-everywhere.steps.home.partnerClientError');
      if (this.isPartnerRemoteError) return this.$t('flows.domo-everywhere.steps.home.partnerRemoteError');
      if (this.isEmailRateLimitError) return this.$t('shared.rateLimitError');
      if (this.isEmailError) return this.$t('shared.businessEmailError');
      return null;
    },

    isManualPartnerEntry() {
      return this.session.partner.value && !!this.$route.query?.utm_partner;
    },

    isSubmitting() {
      return this.isPartnerSubmitting || this.isSubmittingEmail;
    },
  },

  mounted() {
    this.focusEmailInput();
  },

  methods: {
    async handleSubmit() {
      this.hasSubmitted = true;

      if (this.isSubmitting || !this.session.partner.isValidClientSide) return;

      this.isPartnerSubmitting = true;

      const partnerEnv = await this.fetchPartnerEnv(this.session?.partner?.value ?? '', true);

      this.isPartnerSubmitting = false;

      this.isPartnerRemoteError = !partnerEnv;

      if (this.isPartnerRemoteError) return;

      this.session.partner.value = partnerEnv.partner;

      this.submitEmail({
        flowName: this.$route.params.flowName,
        session: this.session,
        onSuccess: this.handleSuccess,
        onError: this.handleError,
      });
    },

    handleSuccess() {
      this.trackEvent('submitted email address', this.currentStep, this.session);

      this.$router.push({
        name: 'step',
        params: {
          lang: this.$route.params.lang || '',
          flowName: this.$route.params.flowName,
          stepName: 'confirm',
        },
        query: this.$route.query,
      });
    },

    handleError() {
      this.focusEmailInput();
    },

    focusEmailInput() {
      setTimeout(() => this.$refs.input.select(), 100);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.Field {
  display: flex;
  padding: 4px;
  align-items: stretch;
  border-radius: $radius;
  background-color: $white;
  box-shadow: 0 8px 48px -8px rgba($black, 0.5);
  .Field__input {
    flex: 1 1 auto;
  }
  .Field__button {
    flex: 0 0 auto;
  }
}

.PartnerBox {
  border: $border;
  border-radius: $radius;
  margin-top: 24px;
  padding: 20px;
}

.__SignIn {
  color: #111111;
  font-size: 14px;
  padding-right: 8px;
  padding-top: 16px;
  text-align: right;
  white-space: nowrap;
}

.__legal-sign-in {
  display: flex;
}
.__legal {
  color: #555555;
  font-size: 12px;
  margin-top: 16px;
  padding-right: 24px;
  text-align: left;
}
.__legal-link {
  color: $link-blue;
  text-decoration: underline;
}

.__LogoBanner {
  margin-top: 40px;
  opacity: 0.46;
}

@media only screen and (max-width: 430px) {
  .Field {
    display: block;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    .Field__input {
      border-radius: $radius;
      background-color: $white;
      box-shadow: 0 16px 56px -8px rgba($black, 0.5);
      flex: none;
      height: 48px;
    }
    .Field__button {
      display: block;
      flex: none;
      margin-top: 8px;
      width: 100%;
    }
  }
  .__SignIn {
    text-align: center;
  }
  .__legal-sign-in {
    display: block;
  }
  .__legal {
    padding-right: 0;
    text-align: center;
  }
}
</style>
